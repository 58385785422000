:root {
  --default-gray: rgba(0,255,0,0.3);
  --default-overlay: rgba(0x33,0x33,0x33,0.84);
}
:root {

  /* Colors: */
  --header-background-1976D2: #1976D2;

  --venous-pressure-1389bf: #1389BF;
  --arterial-pressure-bf1318: #BF1318;
  --bolus-ff7c00: #FF7C00;
  --hf-seal-03309e: #03309E;
  --hf-cut-fff200: #FFF200;
  --alarm-ff0000: #FF0000;
  --warning-fecc00: #FECC00;
  --start-08c127: #08C127;
  --emphasis-711e82: #711E82;
  ---ffffff: #FFFFFF;
  --active-00a97a: #00A97A;
  --non-clickable-333333: #333333;
  ---5b5b5b: #5B5B5B;
  ---848484: #848484;
  ---adadad: #ADADAD;
  ---d6d6d6: #D6D6D6;
  --clickable-6e6e6e: #6E6E6E;
  --surface-f2f2f2: #F2F2F2;
  --dark-blue-00567c: #00567C;
  ---337896: #337896;
  ---669ab0: #669AB0;
  ---99bbcb: #99BBCB;
  ---ccdde5: #CCDDE5;
  --dark-red-830725: #830725;
  ---9c3951: #9C3951;
  ---b56a7c: #B56A7C;
  ---cc9ca8: #CC9CA8;
  ---e6cdd3: #E6CDD3;
  --lime-green-afb635: #AFB635;
  ---bfc55d: #BFC55D;
  ---cfd386: #CFD386;
  ---dfe2ae: #DFE2AE;
  ---eff0d7: #EFF0D7;

  /* Font/text values */
  --unnamed-font-family-arial: Arial;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-36: 36px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-18: 18px;
  --unnamed-line-spacing-21: 21px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-54: 54px;
}

/* Character Styles */
.h1-big-arial-36 {
  font-family: var(--unnamed-font-family-arial);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-36);
  line-height: var(--unnamed-line-spacing-54);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--non-clickable-333333);
}
.h2-med-arial-20 {
  font-family: var(--unnamed-font-family-arial);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-30);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--non-clickable-333333);
}
.h3-small-arial-16 {
  font-family: var(--unnamed-font-family-arial);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--non-clickable-333333);
}
.b1-big-arial-16 {
  font-family: var(--unnamed-font-family-arial);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--non-clickable-333333);
}
.b2-med-arial-14 {
  font-family: var(--unnamed-font-family-arial);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-21);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--non-clickable-333333);
}
.b3-small-arial-12 {
  font-family: var(--unnamed-font-family-arial);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-18);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--non-clickable-333333);
}

$non-clickable-333333: var(--non-clickable-333333);

$default_header_height_small: 43px;
$default_header_height_big: 60px;

$default_spacing_between_tiles_small: 8px;
$default_spacing_between_tiles_big: 12px;

h1 {
  @extend .h1-big-arial-36;
}
h2 {
  @extend .h2-med-arial-20;
}
h3 {
  @extend .h3-small-arial-16;
}

body, p, li, td {
  @extend .b1-big-arial-16;
}

* {
  box-sizing: border-box;
}

body {
//  position: fixed; // prevent any scrolls :(
//  width: 100%;
//  height: 100vh;

//  background: var(--surface-f2f2f2);
  background: var(---ffffff);
  width: 100%;
  height: 100vh;
}

#root {
  height: 100%;

  .App {
    height: 100%;
  }
}


input {
  height: 44px;
  background: var(--surface-f2f2f2);
  color: var(--non-clickable-333333);
  padding: 0 16px 0 16px;

  border: 0px;
  border-radius: 4px;

  @extend .b1-big-arial-16;

  &:focus {
    outline: none !important;
    border:1px solid var(--header-background-1976D2);
//    border-radius: 6px;
//    box-shadow: 0 0 10px #719ECE;
  }
}

.main form {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
form label {
  margin: 12px 0;
}
form .group {
//  border: 1px solid #9c3951;
//  display: inline-block;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input {
    width: 100%;
  }
  span {
    display: none;
  }

}
form .groupicon {
  input {
    padding-right: 56px;
  }

  span {
    display: inline-block;
    height: 24px;
    width: 24px;
    background-color: #08c127;
    position: absolute;
    right: 16px;
    //    transform: translateX(-40px);
  }
}


button {
  height: 44px;
  border-radius: 4px;
  border: 1px solid lighten(#000000, 84%);
  padding-right: 24px;
  padding-left: 24px;

  background: white;
  margin-bottom: 0;

  @extend .h3-small-arial-16;
  color: var(--clickable-6e6e6e);

  &:hover {
    background: lighten(#000000, 84%);
  }
}

.ReactModal__Overlay {
  z-index: 100;
}

.modalDialog {
//  color: $colorPrimaryTrial;
//  position: absolute;
//
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
  border: 1px solid var(--start-08c127);

  div.mainArea {
    overflow: hidden;
    //top: 24px;
    //left: 24px;
    //right: 24px;
    //bottom: 24px;
//    border: 1px solid green;
    margin: 24px;
    margin-top: 0;
    //position: absolute;
  }

  //display: grid;
//  grid-template-columns: 24px 100px 24px;
//  grid-template-rows: 80px auto 80px;
//  grid-column-gap: 1em;
//  grid-row-gap: 0;
//  grid-template-areas: "title title title"
//  "content content content"
//  "button1 button2 button3"
//;
  .title {
    //padding: 0 1em;
    //grid-area: title;
    //align-self: center;
    @extend .h1-big-arial-36;
  }
  .content {
    @extend .b1-big-arial-16;
    //padding: 0 1em;
    //grid-area: content;
  }
  .buttons {
    text-align: right;
  }
}
.modalDialogError {
  border: 1px solid var(--alarm-ff0000);
}
.modalDialogWarning {
  border: 1px solid var(--warning-fecc00);
}
.modalDialogLoading {
  border: 1px solid var(--emphasis-711e82);
}


$default_header_height: $default_header_height_small;

.header {
  background-color: var(--header-background-1976D2);
  color: var(---ffffff);
  height: $default_header_height;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .appname {
    margin-left: 14px;
    color: var(---ffffff);
  }
  .vl {
    border-left: 1px solid var(---ffffff);
    height: 28px;
  }
  .lo {
    height: 28px;
    margin-right: 20px;

    a {
      color: var(---ffffff);
    }
  }
}


@media (min-width: 768px) {
  $default_header_height: $default_header_height_big;

  .header {
    height: $default_header_height;

    .appname {
      margin-left: 20px;
    }
    .vl {
      height: 44px;
    }
  }
//
//  $hamburger-padding-y: 20px;
//  $hamburger-padding-x: 20px;
//  $hamburger-layer-width: 20px;
//  $hamburger-layer-height: 2px;
//  $hamburger-layer-spacing: 5px;
//
//  .hamburger {
//    padding: $hamburger-padding-y $hamburger-padding-x;
//  }
//
//  .hamburger-box {
//    width: $hamburger-layer-width;
//    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
//  }
//
//
//  .hamburger-inner {
//    margin-top: $hamburger-layer-height / -2;
//
//    &,
//    &::before,
//    &::after {
//      width: $hamburger-layer-width;
//      height: $hamburger-layer-height;
//    }
//
//    &::before {
//      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
//    }
//
//    &::after {
//      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
//    }
//  }
}

$default_spacing_between_tiles_small: 8px;
$default_spacing_between_tiles_big: 12px;

.main {
  margin: 0;
  padding-top: $default_header_height_small/2;
  padding-right: $default_spacing_between_tiles_big; // 4.7 only...
  padding-left: $default_spacing_between_tiles_big; // 4.7 only...

  & > div {
    background: white;
    padding: $default_spacing_between_tiles_big;
    margin-bottom: $default_spacing_between_tiles_small;
    border-radius: 4px;

    h1 {
      margin-top: -12px;
    }
    h2 {
      margin-top: -8px;
    }
    h3 {
      margin-top: -4px;
    }
    p {
      margin-top: -4px;
    }
  }

}
@media (min-width: 321px) {
  .main {
    margin: 0;
    padding-top: $default_header_height_small/2;
    padding-right: $default_header_height_small;
    padding-left: $default_header_height_small;

    & > div {
      margin-bottom: $default_spacing_between_tiles_big;
    }
  }
}
@media (min-width: 768px) {
  .main {
    margin: 0;
    padding-top: $default_header_height_big/2;
    padding-right: $default_header_height_big;
    padding-left: $default_header_height_big;

    & > div {
      padding: 2*$default_spacing_between_tiles_big;

      h1 {
        margin-top: -12px;
      }
      h2 {
        margin-top: -8px;
      }
      h3 {
        margin-top: -4px;
      }
    }
  }
}

.main .selectButtons {
  text-align: center;
}

.main .selectPict {
  text-align: center;
  img {
    height: 100px;
    width: auto;
    margin: 0 auto;
  }
}


@import "hamburger";
@import "menu";


@media (min-width: 10024px) {
  .mainwindow > div {
    display: inline-block;
    width: calc((100% - 24px)/3);
    margin-right: 12px;

    .text {
      min-height: 180px;
    }
  }
  .mainwindow >div:nth-child(3n) {
    margin-right: 0;
  }
}


.errorInline {
  background: var(--bolus-ff7c00);
  padding: 12px;
  color: var(---ffffff);
}


.dragdrop {
  border: 2px dashed var(--header-background-1976D2);
  padding: 24px;
}