

.menunow {
  display: block;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .headerPart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $default_header_height;
  }
  .menuPart {
    position: absolute;
    top: $default_header_height;
    left: 0;
    right: 0;
    //bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);

    .menuBar {
      position: relative;
      display: inline-block;
      background: var(---ffffff);
      min-width: 240px;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          margin: 0;
          height: 44px;
          color: var(--clickable-6e6e6e);

          //border-top: 1px solid var(---ffffff);
          //border-bottom: 1px solid var(---ffffff);

          border-left: 4px solid var(---ffffff);
          @extend .h3-small-arial-16;

          a, a:hover, a:visited {
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            padding-right: 24px;
            height: 100%;
            width: 100%;
            @extend .h3-small-arial-16;
            text-decoration: none;
            color: var(--clickable-6e6e6e);

            span {
              display: inline-block;
              width: 22px;
              height: 22px;
              margin: 0;
              margin-left: 22px;
              margin-right: 12px;
              background: #9c3951;
              font-size: 2px;
            }
          }
        }

        li.active {
          color: var(--active-00a97a);
          //border-top: 1px solid var(---adadad);
          //border-bottom: 1px solid var(---adadad);
          border-left: 4px solid var(--active-00a97a);
          a, a:hover, a:visited {
            text-decoration: none;
            color: var(--active-00a97a);
          }
        }
        li:hover:before, li.hover:before {
          content: "";
          border-top: 1px solid var(---adadad);
          border-bottom: 1px solid var(---adadad);
          height: 43px;
          left: 0;
          position: absolute;
          width: 100%;
        }
        li.active:before {
          content: "";
          border-top: 1px solid var(---adadad);
          border-bottom: 1px solid var(---adadad);
          height: 43px;
          left: 0;
          position: absolute;
          width: 100%;
        }
        li:hover, li.hover {
          //border-top: 1px solid var(---adadad);
          //border-bottom: 1px solid var(---adadad);
          border-left: 4px solid transparent;
          background: rgba(#333333, 0.16);
        }
        li.active:hover {
          //border-top: 1px solid var(---adadad);
          //border-bottom: 1px solid var(---adadad);
          border-left: 4px solid var(--active-00a97a);
          background:  var(---ffffff);
          a, a:hover, a:visited {
            text-decoration: none;
            color: var(--active-00a97a);
          }
        }
      }
    }
  }
}

.menuhidden {
  display: none;
}

@media (min-width: 768px) {
  $default_header_height: $default_header_height_big;

  .menunow {
    .headerPart {
      height: $default_header_height;
    }
    .menuPart {
      top: $default_header_height;
    }
  }
}